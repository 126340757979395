import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, forwardRef } from '@angular/core';
import { 
  NG_VALUE_ACCESSOR, ControlValueAccessor 
} from '@angular/forms';
 
@Directive({
  selector: '[magicMask]',
  host: {
    '(blur)': 'registerOnTouched()'
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => MagicMaskDirective), 
    multi: true 
  }]
})

export class MagicMaskDirective implements ControlValueAccessor {

  onTouched: any;
  onChange: any;
 
  @Input('magicMask') magicMask: string;
  @Input('lengthAlternative') lengthAlternative: number;
  @Input('magicMaskAlternative') maskAlternative: string;

  constructor(private el: ElementRef) {}
 
  writeValue(value: any): void {
      if (value) {
        
        this.el.nativeElement.value = this.applyMask(value);
      }
  }
 
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
 
  registerOnTouched(fn: () => any): void { this.onTouched = fn }
    
 
  @HostListener('keyup', ['$event']) 
  onKeyup($event: any) {
    var valor = $event.target.value.replace(/\D/g, '');
    valor = this.applyMask($event.target.value);
 
    if ($event.keyCode === 8) {
      this.onChange(valor);
      return;
    }
 
    $event.target.value = valor;
  }
 
  @HostListener('blur', ['$event']) 
  onBlur($event: any) {
    //this.el.nativeElement.value = $event.target.value;
  }

  checkAlternativeMaskByType(mask: string, value: string){
    
      if(mask!=undefined){
        if(value.length>this.lengthAlternative){
          return mask;
        }
      }

      return this.magicMask;
  }

  applyMask(value: any) {

    var valor = value.replace(/\D/g, '');
    
    var maskDefault:string = this.checkAlternativeMaskByType(this.maskAlternative, valor);

    var pad = maskDefault.replace(/\D/g, '').replace(/9/g, '_');
    var valorMask = valor + pad.substring(0, pad.length - valor.length);
    if (pad.length <= valor.length) {
      valor  = valor.substring(0,pad.length);
    }
   
    this.onChange(valor);
    var valorMaskPos = 0;
    valor = '';
    for (var i = 0; i < maskDefault.length; i++) {
      if (isNaN(parseInt(maskDefault.charAt(i)))) {
        valor += maskDefault.charAt(i);
      } else {
        valor += valorMask[valorMaskPos++];
      }
    }
    
    if (valor.indexOf('_') > -1) {
      valor = valor.substr(0, valor.indexOf('_'));
    }
    
   
    return valor;
  }

    
  
}