import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dataProcessCancel } from 'src/app/model/dataProcessCancel.model';
import { Exam } from 'src/app/model/exam.model';
import { Request } from 'src/app/model/request.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProtocolService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  getProtocol(number:string):Observable<any> {
    return this.http.get<Request>(this.urlAdminBe + '/protocol/'+number)
    .pipe(map(data => data));
  }

  activeProtocols(active:boolean, data:dataProcessCancel[]):Observable<dataProcessCancel[]> {
    return this.http.post<dataProcessCancel[]>(this.urlAdminBe + "/protocol/processAll/" + active, data)
    .pipe(map(data => data));
  }

  removeProtocols(data:dataProcessCancel[]){

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };

    return this.http.delete<boolean>(this.urlAdminBe + "/protocol/processAll", options)
    .pipe(map(data => data));
  }

}
